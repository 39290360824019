import Chatbox from "../Components/Chatbox";
import Footer from "../Components/footer";
import Header from "../Components/Header";
import SEOPage from "../Components/SEOPage";



const LookplexProject = () => {

    const ankit = "https://www.datametricks.com/website-image/ankitimg.svg";
    const rajesh = "https://www.datametricks.com/website-image/rajeshimg.svg";
    const ravi = "https://www.datametricks.com/website-image/raviimg.svg";
    const harsh = "https://www.datametricks.com/website-image/harshimg.svg";
    const kritesh = "https://www.datametricks.com/website-image/kriteshimg.svg";
const lookproimg1 = "https://www.datametricks.com/website-image/lookproimg2.svg";
const casestudyimg1 = "https://www.datametricks.com/website-image/lookpleximg1.svg";
const casestudyimg2 = "https://www.datametricks.com/website-image/lookpleximg2.svg";
// const casestudyimg3 = "https://www.datametricks.com/website-image/casestudy-img3.svg";
const casestudyimg4 = "https://www.datametricks.com/website-image/casestudy-img4.svg";
// const casestudyimg6 = "https://www.datametricks.com/website-image/lookpleximg4.svg";
const casestudyimg7 = "https://www.datametricks.com/website-image/casestudy-img7.svg";

    return (

        <div>
             <SEOPage 
                title="Lookplex: Salon at Home Website Design & Development | Beauty Services Platform"
                description="We designed and developed the Lookplex website, a sleek and user-friendly platform for booking at-home beauty services with ease."
                canonicalUrl="https://www.datametricks.com/our-completed-projects/lookplex"
            />
            
            <div>
                <Header/>
            </div>

         <section className="mt90" style={{marginTop:'130px'}}>
                <div className="container casestudycontainer position-relative">
                 <div className="image-wrapper">
                       <img src={casestudyimg1} alt="" className="img-fluid w-100 casestudyimg-fluid" />
                      <div className="casestudytext-overlay text-center text-white">
                            <p className="casestudyheadline mb-0">Creative Design and Development</p>
                            <p className="casestudyheadline">for Lookplex Website</p>
                     </div>
                </div>
              </div>
        </section>

        <section>
            <div className="container text-center mt-4">
                <img src={casestudyimg2} alt="" className="img-fluid" />
            </div>
        </section>

        <section>
            <div className="container mt-lg-5 mt-20">
                <div className="row justify-content-center">
                    <div className="col-lg-5 textcenter">
                        <p className="fw-600 fs-24 plus font-24" style={{color:'rgba(64, 64, 64, 1)'}}>Project Summary</p>
                        <p className="fs-16 font-16 plus" style={{color:'rgba(82, 86, 101, 1)'}}>Our client, a provider of salon services at home, needed a sleek and modern website to facilitate easy booking and showcase their range of beauty services. The objective was to create an online presence that reflects the quality and convenience of their services, attracting more customers and streamlining the booking process.</p>
                        {/* <p className="fs-16 font-16 plus " style={{color:'rgba(82, 86, 101, 1)'}}>Social connections may also impact the relevancy of some search results, either within a social media network or at a ‘mainstream’ search engine. Social media itself is a catch-all term for sites that may provide radically different social actions.</p> */}

                     
                      <div className="d-flex flex-wrap gap-3 justify">
                        <div>
                            <button type="button" class="btn  btn-sm font-16  fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Designer</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16  fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Developer</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm  font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Html & Css</button>
                        </div>
                     </div>
                     <div className="d-flex gap-3 pt-3 justify">
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Javascript</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Bootstrap</button>
                        </div>
                        <div>
                            <button type="button" class="btn  btn-sm font-16 fs-16 fw-500 mulish px-4 py-2" style={{border:'1px solid rgba(0, 83, 154, 1)',borderRadius:'5px',boxShadow:'none',color:'rgba(0, 83, 154, 1)'}}>Next.js</button>
                        </div>
                        
                     </div>
                        
                    </div>
                    <div className="col-lg-5 ps-lg-5 pl0 pl-0 ">
                        <p className="fw-600 fs-24 plus textcenter pt-20 font-24" style={{color:'rgba(64, 64, 64, 1)'}}>Project Details</p>
                        <div className="p-4  " style={{backgroundColor:'rgba(251, 251, 251, 1)',borderRadius:'16px'}}>
                          <p className="fw-600 fs-16 plus mb-0 font-16" style={{color:'rgba(39, 39, 39, 1)'}}>Project Type : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}>  Salon at Home Services Website </span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Duration : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}> 20 Days </span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Website : <span className="fw-700 fs-15 plus font-16" style={{color:'rgba(115, 144, 249, 1)'}}> N/A </span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Tools : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}> NEXT.JS , JAVASCRIPT , BOOTSTRAP , HTML , CSS    </span> </p>
                            <p className="fw-600 fs-16 plus mb-0 font-16 pt-3" style={{color:'rgba(39, 39, 39, 1)'}}>Team : <span className="fw-500 fs-14 plus font-16" style={{color:'rgba(82, 86, 101, 1)'}}> Kritesh , Rajesh , Ravi , Ankit, Harsh  </span> </p>

                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className="container mt-5 mt-10">
        <div className="p-4 " style={{border:'0.90px solid rgba(211, 211, 211, 1)',borderRadius:'16px'}} >

            <div className="row text-center"> 
            <div className="d-block d-sm-block d-lg-none d-md-none">
                    <p className="fw-600 fs-24 font-23 plus  " style={{color:'rgba(64, 64, 64, 1)'}}>Team Member</p>
                </div>
           
            <div className="col-lg-2 col-md-6 col-6 d-none d-sm-none d-lg-block d-md-block">
                <div>
                    <p className="fw-600 fs-24 font-24 plus pt-5 " style={{color:'rgba(64, 64, 64, 1)'}}>Team Member</p>
                </div>
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={kritesh} alt="" className="img-fluid" />
                        <p className="fw-600 fs-16 plus font-16 mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Kritesh Singh</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>Developer</p>
                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={rajesh} alt="" className="img-fluid" />
                        <p className="fw-600 fs-16 font-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Rajesh Pandey</p>
                        <p className="fs-14 plus font-16" style={{color:'rgba(151, 151, 151, 1)'}}>Developer</p>
                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={ravi} alt="" className="img-fluid" />
                        <p className="fw-600 fs-16 font-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Ravi Chaudhary</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>DevOps</p>
                </div>
         
            </div>

            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={ankit} alt="" className="img-fluid" />
                        <p className="fw-600 font-16 fs-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Ankit Kumar</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>Developer</p>
                </div>
         
            </div>
            
            <div className="col-lg-2 col-md-6 col-6 ">
                 <div>
                        <img src={harsh} alt="" className="img-fluid" />
                        <p className="fw-600 font-16 fs-16 plus mb-0 pt-2" style={{color:'rgba(64, 64, 64, 1)'}}>Harsh Rohilla</p>
                        <p className="fs-14 font-16 plus" style={{color:'rgba(151, 151, 151, 1)'}}>Designer</p>
                </div>
         
            </div>
           </div>
            </div>
           
        </section>


        <section>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <img src={casestudyimg4} alt="" className="img-fluid" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-12 textcenter">
                        <p className="fw-700 font-24 fs-30 plus pt-20">Make Wireframes for Every Screens</p>
                        <p className="fs-18 font-16 plus" style={{color:'rgba(46, 46, 46, 0.6)'}}>Wireframes were created to define the user journey, focusing on ease of navigation and quick access to booking features. The design phase involved creating a stylish and modern interface that appeals to the target audience. We used high-quality images, elegant typography, and a clean layout to create a luxurious feel. The color scheme and design elements were chosen to reflect the brand's identity and appeal to a beauty-conscious audience.</p>
                        {/* <p className="fs-18 plus font-16" style={{color:'rgba(46, 46, 46, 0.6)'}}>Social connections may also impact the relevancy of some search results, either within a social media network or at a ‘mainstream’ search engine. Social media itself is a catch-all term for sites that may provide radically different social actions.</p> */}
                    </div>
                </div>

            </div >   
        </section>

        <section>
            <div className=" mt-5">
                <img src={lookproimg1} alt="" className="img-fluid" />
            </div>
        </section>

        <section className="mt-4 text-center">
            <p className="fw-600 fs-40 plus newfont-24  ">Our Next Project</p>
            <div className="mt-4">
                <img src={casestudyimg7} alt="" className="img-fluid" />
            </div>

        </section>

     

<section className=''>
        <div className='newhome-bg1 resnewhome-bg1'>  
  <div className='container p-30'>
    <div className='row justify-content-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <p className='fs-50 fw-600 plus mb-0 text-white font-25 mt-35' style={{marginTop:'200px'}}>Contact for your </p>
          <p className='fs-50 fw-700 plus mt0 font-25 mt-35' style={{color:'rgba(255, 162, 49, 1)'}}> New Website</p>

      </div>
      <div className='col-lg-4 col-md-6 col-12'>
          <div className='container p-4 mt-10' style={{backgroundColor:'rgba(255, 255, 255, 1)',borderRadius:'10px',marginTop:'120px'}}>
              <p className='fs-22 fw-400 plus mb-4'>Get a Free Consultaion</p>
              <div class="custom-input-container ">
                <input type="text" id="customFullName" name="customFullName" required />
                <label for="customFullName">Full Name</label>
            </div>
            <div class="custom-input-container mt-4 ">
                <input type="tel" id="mobilenumber" name="mobilenumber" required />
                <label for="mobilenumber">Mobile Number</label>
            </div>
            <div class="custom-input-container mt-4 ">
                <input type="email" id="email" name="email" required />
                <label for="email">Email</label>
            </div>
            <div class="custom-input-container mt-4 ">
                <input type="text" id="servicename" name="servicename" required />
                <label for="servicename">Service Name</label>
            </div>
            <div className='mt-5 '>
            <button type="button" class="btn  btn-sm  w-100 submit-button fs-20 fw-600 plus text-white  mb-2" style={{backgroundColor:'rgba(255, 162, 49, 1)',boxShadow:'none',border:'none',outline:'none'}}>Submit</button>
            </div>
          </div>
        </div>
    </div>
  </div>
  </div>
  
</section>
       


<section>
  <div>
    <Chatbox/>
  </div>
</section>


            <div className="">
                <Footer/>
            </div>


        </div>

    );
};
export default LookplexProject;