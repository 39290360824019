import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Testimonial1 from "../Components/Testimonial1";
import Faq from "../Components/Faq";
import Bigcontact from '../Components/Bigcontact';
import DigitalmarketingCard from '../Components/DigitalmarketingCard';
import Header from "../Components/Header";
import Footer from "../Components/footer";
import Minicontact from "../Components/Minicontact";
import Chatbox from '../Components/Chatbox';
import SEOPage from '../Components/SEOPage';
import Blog1 from '../Components/Blog1';
import { Helmet } from "react-helmet";




const Digitalmarketing = () => {

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
      .then((response) => response.json())
      .then((data) => setCategories(data.slice(0,3)))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);

 
  const categoryId=18;

  const serviceSchemaData4 = 
  [
  {
    "@context": "https://schema.org",
    "@type": "Service",
    "serviceType": "Digital Marketing",
    "provider": {
      "@type": "Organization",
      "name": "Datametricks Consulting Pvt Ltd",
      "url": "https://www.datametricks.com/",
      "logo": "https://www.datametricks.com/website-image/comlogo.svg",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 7303946657",
        "contactType": "Customer Service",
        "email": "contact@datametricks.com"
      }
    },
    "description": "Datametricks offers comprehensive digital marketing services including SEO, social media marketing, content marketing, and performance marketing to boost your online presence.",
    "areaServed": {
      "@type": "Place",
      "name": "India"
    }
  }
];


const dgimg1 = "https://www.datametricks.com/website-image/Dgimg1.png";
const eye = "https://www.datametricks.com/website-image/eye.png";
const target = "https://www.datametricks.com/website-image/target.png";
const handshake = "https://www.datametricks.com/website-image/handshake.png";
const trophy = "https://www.datametricks.com/website-image/trophy.png";
const digiimg1 = "https://www.datametricks.com/website-image/digi-img1.svg";
const digiimg2 = "https://www.datametricks.com/website-image/digi-img2.svg";
const digiimg3 = "https://www.datametricks.com/website-image/digi-img3.svg";
const digiimg4 = "https://www.datametricks.com/website-image/digi-img4.svg";
const digiimg5 = "https://www.datametricks.com/website-image/digi-img5.svg";
const digiimg6 = "https://www.datametricks.com/website-image/digi-img6.svg";
// const testimonialimage = "https://www.datametricks.com/website-image/testimonial-img.png";

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var faqData=[
      {que:"1. What digital marketing services do you offer?",ans:"We offer a wide range of digital marketing services, including: (i) Search Engine Optimization (SEO) (ii) Pay-Per-Click (PPC) Advertising (iii) Content Marketing (iv) Social Media Marketing (v) Email Marketing (vi) Web Analytics and Reporting , We can tailor a custom strategy to meet your specific needs and budget." },
      {que:"2. How long does it take to see results from digital marketing? ", ans:"The timeline for seeing results varies depending on several factors, including your industry, competition, budget, and the specific services you're using. However, with our data-driven approach and proven strategies, you can typically expect to see noticeable improvements in your online visibility and traffic within a few months."},
      {que:"3. How do you measure the success of your digital marketing campaigns? ",ans:"We believe in transparency and accountability. We use various metrics to track the success of our campaigns, including website traffic, lead generation, conversions, click-through rates, and return on investment (ROI). We provide regular reports and insights so you can see the impact of our efforts."},
      {que:"4. What is your pricing structure for digital marketing services? ",ans:"Our pricing is customized to fit your unique needs and goals. We offer flexible packages that can be tailored to your budget and specific requirements. Contact us for a free consultation to discuss your options. "},
      {que:"5. How do I get started with your digital marketing services?  ",ans:"Getting started is easy! Simply contact us through our website or give us a call. We'll schedule a free consultation to discuss your business goals and how we can help you achieve them through digital marketing. "},
    ];

  return (
    <div>
      <SEOPage 
                title="Digital Marketing Services | Datametricks - Your Growth Partner"
                description="Grow your business with Datametricks' digital marketing services, including SEO, content
marketing, social media, and performance marketing to boost your online visibility and
generate leads."
                canonicalUrl="https://www.datametricks.com/services/digital-marketing" />

        <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(serviceSchemaData4)}
        </script>
      </Helmet>

      {/* section 1 */}
      <section>
        <Header />
      </section>
      {/* section 2 */}
      <section>
        <div className="container  DigitalSec">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 ">
              <div style={{ lineHeight: "1.3" }}>
                <p className="font-family Seopara1 fw-800 fc-black fs-40 mb-0 font-23">
                Unlock Your Business Potential with Expert Digital Marketing  Services
                </p>
              </div>

              <p className="para1 Seopara2 pt-2">
              Driving Growth, Engagement, and Success Through Strategic Digital Marketing .
              </p>
              {/* =========================Button Modal============= */}
         <div>
              <button className=" Seopara3 git-button font-family fw-500 fs-18 "  onClick={handleShow}>
                Get in Touch
              </button>  
      

      <Modal show={show} onHide={handleClose} className='modal1'   >
      <Modal.Header closeButton className='modelheader1' ></Modal.Header>
        <Modal.Body className='modelbody1'>  
           <Bigcontact/>
        </Modal.Body>
       
      </Modal>
    
             
         </div>
         {/* ===========Button Model End===================== */}

         </div>
            <div className="col-lg-5 col-md-6 col-12">
              <img src={dgimg1} alt=""  className='img-fluid'/>
            </div>
          </div>
        </div>
      </section>

      {/* section 2 */}
      <section>
        <div className="container text-center pt-lg-5 mt-30">
          <h1 className=" Seopara1 font-family fw-700 fc-black fs-40 mb-0 font-23">
          Get Found, Get Leads, Get Results: 
          </h1>
          <h1 className="Seopara1 font-family fw-700 fc-black fs-40 font-23">Your Digital Marketing Solution </h1>
          <p className=" Seopara3 para1 pt-4 font-18">
          Your business is unique, and so are your digital marketing needs. We take the time to understand your goals, your target audience, and your brand identity. Our personalized approach ensures that every campaign is tailored to your specific objectives, whether it's increasing website traffic, generating leads, or boosting sales. 
          </p>
        </div>
      </section>
      {/* section 3 */}
      <section>
        <div className="sec2 mt-4 ">
          <div className="container text-center pt-lg-5">
            <h1 className="Seopara1 font-family fw-700 fc-black fs-40 mb-0 font-23">
            Digital Marketing Is Your Key to Online    Success 
            </h1>
          </div>


          <div>
            <DigitalmarketingCard/>
          </div>


          {/* 1 */}
          {/* <div className="container"> */}
            {/* <div className="dgimg2 mt-5">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 ps-5 pt-4">
                    <p className="cutting-title">Strategy & Consulting</p>
                    <p className="cutting-subtitle">
                      Lorem ipsum dolor sit amet consectetur. Lacus at tellus
                      pulvinar a ut risus. Sollicitudin venenatis sit vehicula
                      velit. Convallis ultrices fermentum facilisis pellentesque
                      pharetra egestas pulvinar. Mauris duis mauris vitae
                      adipiscing. Arcu nunc sed fusce dictum pretium. Id at
                      adipiscing massa a malesuada pretium nulla non. Ut neque
                      hendrerit egestas ut tellus vulputate.
                    </p>
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </div>
            </div> */}
            {/* 2nd */}

            {/* <div className="dgimg3 mt-5">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6  mt-5">
                    <p className="cutting-title">UI/UX Design</p>
                    <p className="cutting-subtitle">
                      Lorem ipsum dolor sit amet consectetur. Lacus at tellus
                      pulvinar a ut risus. Sollicitudin venenatis sit vehicula
                      velit. Convallis ultrices fermentum facilisis pellentesque
                      pharetra egestas pulvinar. Mauris duis mauris vitae
                      adipiscing. Arcu nunc sed fusce dictum pretium. Id at
                      adipiscing massa a malesuada pretium nulla non. Ut neque
                      hendrerit egestas ut tellus vulputate.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="dgimg4 mt-5">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 ps-5 pt-4">
                    <p className="cutting-title">
                      Native App Development (Android/iOS)
                    </p>
                    <p className="cutting-subtitle">
                      Lorem ipsum dolor sit amet consectetur. Lacus at tellus
                      pulvinar a ut risus. Sollicitudin venenatis sit vehicula
                      velit. Convallis ultrices fermentum facilisis pellentesque
                      pharetra egestas pulvinar. Mauris duis mauris vitae
                      adipiscing. Arcu nunc sed fusce dictum pretium. Id at
                      adipiscing massa a malesuada pretium nulla non. Ut neque
                      hendrerit egestas ut tellus vulputate.
                    </p>
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </div>
            </div> */}
            {/* 4th */}
            {/* <div className="dgimg5 mt-5">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6  mt-5">
                    <p className="cutting-title">
                      Cross-Platform App Development
                    </p>
                    <p className="cutting-subtitle">
                      Lorem ipsum dolor sit amet consectetur. Lacus at tellus
                      pulvinar a ut risus. Sollicitudin venenatis sit vehicula
                      velit. Convallis ultrices fermentum facilisis pellentesque
                      pharetra egestas pulvinar. Mauris duis mauris vitae
                      adipiscing. Arcu nunc sed fusce dictum pretium. Id at
                      adipiscing massa a malesuada pretium nulla non. Ut neque
                      hendrerit egestas ut tellus vulputate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="dgimg6 mt-5">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-6 ps-5 pt-4">
                    <p className="cutting-title">
                      App Development for Wearables
                    </p>
                    <p className="cutting-subtitle">
                      Lorem ipsum dolor sit amet consectetur. Lacus at tellus
                      pulvinar a ut risus. Sollicitudin venenatis sit vehicula
                      velit. Convallis ultrices fermentum facilisis pellentesque
                      pharetra egestas pulvinar. Mauris duis mauris vitae
                      adipiscing. Arcu nunc sed fusce dictum pretium. Id at
                      adipiscing massa a malesuada pretium nulla non. Ut neque
                      hendrerit egestas ut tellus vulputate.
                    </p>
                  </div>
                  <div className="col-lg-4"></div>
                </div>
              </div>
            </div>
          </div> */}

        </div>
      </section>

      {/* section 5 */}
      <section>
        <div className="text-center  mt-lg-2">
          <h1 className=" Seopara1 fw-700 font-family fs-40 fc-black font-23">
          A Strategic Approach to Digital Growth 
          </h1>
        </div>

        <div className='container mt-lg-5 mt-10'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={digiimg1} alt='' className='img-fluid '/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 textcenter pt-10'>
                            <p className="pt-3 font-18" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We understand that a strong digital presence is essential for business success. Our strategic approach to digital growth encompasses a range of services designed to enhance your online visibility, engage your audience, and drive measurable results.   </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 textcenter'>
                            <p className="pt-3" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> We start by developing a comprehensive understanding of your business, products, and target audience. This allows us to create customized digital marketing strategies that align with your goals and deliver impactful results. From content marketing and SEO to social media management and PPC advertising, we utilize the latest techniques and tools to maximize your digital performance.    </p>
                        </div>
                        </div>

                        <div className='col-lg-4 pt-10'> 
                            <img src={digiimg2} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4 '> 
                            <img src={digiimg3} alt='' className='img-fluid d-none d-sm-none d-lg-block d-md-block'/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 pt-10 textcenter'>
                            <p className="pt-3" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> A compelling digital strategy isn't just about what you sell – it's about telling your brand's story and showcasing your unique value proposition. We focus on creating engaging content that resonates with your audience and fosters long-term relationships. Our team also ensures that your digital campaigns are data-driven, continually optimized, and aligned with the latest industry trends.    </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 textcenter'>
                            <p className="pt-lg-3 pt-10" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Let Datametricks help you navigate the digital landscape and achieve sustainable growth. Explore our services and discover how we can take your business to the next level.    </p>
                        </div>
                        </div>

                        <div className='col-lg-4 pt-10'> 
                            <img src={digiimg4} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

                <div className='container mt-lg-5 mt-30 textcenter'>
                    <div className='row justify-content-center'>
                        <div className='col-lg-4'> 
                            <img src={digiimg5} alt='' className='img-fluid d-none d-sm-none d-md-block d-lg-block'/>
                        </div>
                        <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4'>
                            <p className="pt-lg-3" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}> Digital marketing helps businesses reach wider audiences, engage customers effectively, and drive growth. We specialize in creating tailored digital marketing strategies that include content marketing, SEO, social media management, and PPC advertising. Our goal is to enhance your visibility, build customer loyalty, and boost your bottom line.     </p>
                        </div>
                        </div>
                      
                    </div>
                </div>

                <div className='container mt-lg-5'>
                    <div className='row justify-content-center'>

                    <div className='col-lg-6 '> 
                        <div className=' my-lg-5 mx-lg-4 textcenter'>
                            <p className="pt-3" style={{fontSize:'16px', fontWeight:'400', fontFamily:"Plus Jakarta Sans, sans-serif", }}>Let us help you navigate the digital world and achieve your business goals. Contact us today to learn more about our digital marketing services.    </p>
                        </div>
                        </div>

                        <div className='col-lg-4 pt-10'> 
                            <img src={digiimg6} alt='' className='img-fluid  '/>
                        </div>
                      
                      
                    </div>
                </div>

      </section>
      {/* section 6 */}
      <section>
        <div className="text-center pt-lg-5 mt-30">
          <h1 className=" Seopara1 fw-700 font-family fs-40 fc-black font-23">
          How Digital Marketing Can Transform   Your Business 
          </h1>
       
        </div>
        <div className="container">
          <div className="row pt-lg-5  mt-30 ">
            <div className="col-lg-3 textcenter">
              <div className="benifit-card">
                <img src={eye} alt="" />
                <h1 className="fs-22 fw-600 fc-black font-family pt-4 font-23">
                  Increased Visibility
                </h1>
                <p className="para1 font-18">
                Boost your business's online presence with SEO and local SEO, making it easier for Noida consumers to find you. 
                </p>
              </div>
            </div>
            <div className="col-lg-3 pt-20 textcenter">
              <div className="benifit-card">
                <img src={target} alt="" />
                <h1 className="fs-22 fw-600 fc-black font-family pt-4 font-23">
                  Targeted Reach
                </h1>
                <p className="para1 font-18">
                Use PPC and social media ads to precisely target your ideal demographics in Noida, increasing conversion rates. 
                </p>
              </div>
            </div>
            <div className="col-lg-3 pt-20 textcenter">
              <div className="benifit-card">
                <img src={handshake} alt="" />
                <h1 className="fs-22 fw-600 fc-black font-family pt-4 font-23">
                  Engagement with Local Community
                </h1>
                <p className="para1 font-18">
                Connect with the Noida community through social media and content marketing, building strong local relationships.
                </p>
              </div>
            </div>
            <div className="col-lg-3 pt-20 textcenter">
              <div className="benifit-card p-20">
                <img src={trophy} alt="" />
                <h1 className="fs-22 fw-600 fc-black font-family pt-4 font-23">
                  Competitive Edge
                </h1>
                <p className="para1 font-18">
                  {" "}
                  Gain a competitive advantage with a strong digital presence, helping your business stand out in a crowded market. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>




      <section>
            <div className='mt-lg-4'>
            <div >
                <Testimonial1/>
              </div>
            </div>
        </section>

        <section className='mt-lg-4 mt-10'>
<div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>
  <div className='mt-4'>
  <Blog1 categoryId={categoryId}/>
  </div>
</section>


      {/* testimonial */}
      {/* <section>
      <div className="text-center pt-lg-5">
          <h1 className=" Seopara1 fw-700 font-family fs-40 fc-black font-23">
          "Client Success Stories"
          </h1>
      </div>
        <div className="container mt-5  ">
        <Testimonial 
             paragraph="Datametricks completely transformed our online visibility. Their SEO expertise catapulted our website to the top of search results, driving a significant increase in organic traffic and leads. We're now reaching more customers than ever before!" title="Christy Yoland R." subtitle="CEO Founder Loker Indonesia"image={testimonialimage}
             paragraph1="Our PPC campaigns with Datametricks have been a game-changer for our business. They developed targeted ads that reached our ideal customers, resulting in a huge boost in qualified leads and conversions. We're thrilled with the ROI we've seen."title1="Christy Yoland R." subtitle1="CEO Founder Loker Indonesia"image1={testimonialimage}
             paragraph2="Datametricks' content marketing strategy has been instrumental in establishing our brand as a thought leader in our industry. Their engaging and informative content has attracted a loyal following, increased website traffic, and strengthened our overall brand awareness."title2="Christy Yoland R." subtitle2="CEO Founder Loker Indonesia"image2={testimonialimage}
             paragraph3="Datametricks has become our trusted partner for all things digital marketing. Their holistic approach, combining SEO, PPC, content marketing, and social media, has taken our online presence to new heights. We're grateful for their expertise and dedication to our success."title3="Christy Yoland R." subtitle3="CEO Founder Loker Indonesia"image3={testimonialimage}
             paragraph4="Datametricks has been our trusted partner for over a year. They've consistently delivered outstanding results, helping us maintain top rankings and drive organic traffic to our website. Their team is always responsive, proactive, and goes above and beyond to ensure our success. We highly recommend them for any business looking to improve their online visibility."title4="Christy Yoland R." subtitle4="CEO Founder Loker Indonesia"image4={testimonialimage}

             />
        </div>
      </section> */}

      {/* faq */}
      <section>
        <div className="mt-30 mt-lg-5">
            <Faq faqData={faqData} />
        </div>
      </section>

      {/* insights */}
      {/* <section>
        <div className="container text-center pt-5">
          <h1 className="font-family fw-700 fc-black fs-40 mb-0">
            Insights and Updates on Digital
          </h1>
          <h1 className="font-family fw-700 fc-black fs-40 mb-3">Marketing</h1>
        </div>
        <div className="greybg pt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="insight-card">
                  <div className="insight-card-img"></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="insight-card">
                  <div className="insight-card-img"></div>
                  <ul>
                    <li>Coffee</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="insight-card">
                  <div className="insight-card-img"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className='pt-5 container' >
        <Minicontact />
      </section>

      <section>
  <div>
    <Chatbox/>
  </div>
</section>

      <section className='mt-5'>
        <Footer />
      </section>
    </div>
  );
};
export default Digitalmarketing;
