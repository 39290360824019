const Video = ()=> {

    const bgvideo = "https://www.datametricks.com/website-image/bgvideo.mp4";


    return(

    <div>
            <section>
                <div className="background-video" style={{position:'relative'}}>
                    <video autoPlay muted loop >
                        <source src={bgvideo} type="video/mp4" />
                    </video>
                    <div className="video-content text-center " style={{position:'absolute',top:'0',backgroundColor:'rgba(0, 0, 0, 0.50)',height:'92vh',width:'100%'}}>
                     <div style={{marginTop:'230px'}}>
                        <p className="fs-50 fw-700 plus text-white font-23 mb-0">Transforming Businesses with Intelligent</p>
                        <p className="fs-50 fw-700 plus text-white font-23 "> Web and Marketing Solutions</p>

                        
                        <button type="button" class="btn px-5  pb-2 font-18 getstartedbtn " style={{backgroundColor:'rgba(0, 110, 205, 1)',borderRadius:'50px',color:'white',fontSize:'22px',fontWeight:'600',border:'none',boxShadow:'none',  overflow: 'hidden', position: 'relative',  transition: 'background-color 0.3s ease'}}>
                        <span className="getstarted-text">
                                 Get Started
                        </span>
                        </button>
                     </div>
                    </div>
                </div>
            </section>
     

    </div>


    )
}
export default Video;