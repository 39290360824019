import React, { useState } from 'react';


const Chatbox = () => {
  const chatboxicon = "https://www.datametricks.com/website-image/chatbotimg 1.svg";
const chatbotlogo = "https://www.datametricks.com/website-image/chatbotlogo.svg";
const chatbotcross = "https://www.datametricks.com/website-image/chatboxcross.svg";

  // State to manage the visibility of the chatbox
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [messages, setMessages] = useState([
    { text: "Welcome to Datametricks! This is your virtual assistant.", type: "bot-message" }
  ]);

  // Function to handle clicking the chatbox icon
  const handleChatboxIconClick = () => {
    setIsChatOpen(true); // Show the chatbox
  };

  // Function to handle closing the chatbox
  const handleChatbotCloseClick = () => {
    setIsChatOpen(false); // Hide the chatbox
  };

  // Function to handle sending a message
  // const sendMessage = (userMessage) => {
  //   if (userMessage.trim()) {
  //     setMessages(prevMessages => [
  //       ...prevMessages,
  //       { text: userMessage, type: "user-message" }
  //     ]);
  //   }
  // };

  // Function to handle Enter key press for sending messages
  // const handleKeyPress = (event) => {
  //   if (event.key === 'Enter') {
  //     sendMessage(event.target.value);
  //     event.target.value = '';
  //   }
  // };

  // Function to handle selecting a service
  const selectService = (serviceName) => {
    setMessages(prevMessages => [
      ...prevMessages,
      { text: `You have selected ${serviceName}. Please fill out the form for more details.`, type: "user-message" }
    ]);
    setIsFormVisible(true);
  };

  // Function to handle form submission
  const submitForm = (event) => {
    event.preventDefault();
    const name = event.target.name.value;
    const email = event.target.email.value;
    // const message = event.target.message.value;

    setMessages(prevMessages => [
      ...prevMessages,
      { text: `Thank you ${name}! We have received your message. We will contact you at ${email}.`, type: "bot-message" }
    ]);

    setIsFormVisible(false);
  };

  return (
    <div className="unique-chatbox-wrapper">

    {/* Chatbox Icon */}
    {!isChatOpen && (
      <div className="unique-chatbox-icon" onClick={handleChatboxIconClick}>
        <img src={chatboxicon} alt="Chatbox Icon" className='chatboxupdown-icon'/>
      </div>
    )}

    {/* Chat Container */}
    {isChatOpen && (
      <div className="unique-chat-container">
        <div className="unique-chat-header">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <div style={{ marginTop: '5px' }}>
                <img src={chatbotlogo} alt="Chatbot Logo" />
              </div>
              <div>
                <p className="plus fw-500 fs-18" style={{ color: 'hsla(0, 0%, 100%, 1)',marginTop:'12px' }}>Datametricks</p>
              </div>
            </div>
            <div className="unique-chatbot-close" style={{ marginTop: '15px' }} onClick={handleChatbotCloseClick}>
              <img src={chatbotcross} alt="Close Chat" />
            </div>
          </div>
        </div>

   
        <div className="unique-chat-box">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.type}`}>
              {message.text}
            </div>
          ))}

          {!isFormVisible && (
            <div className="unique-service-container message bot-message-service">
              <h3>Our Services</h3>
              <div className="unique-service-buttons">
                <button onClick={() => selectService('Website Development')}>Website Development</button>
                <button onClick={() => selectService('Mobile Development')}>Mobile Development</button>
                <button onClick={() => selectService('Social Media')}>Social Media</button>
                <button onClick={() => selectService('Graphic Design')}>Graphic Design</button>
                <button onClick={() => selectService('Digital Marketing')}>Digital Marketing</button>
                <button onClick={() => selectService('SEO')}>SEO</button>
              </div>
            </div>
          )}

          {isFormVisible && (
            <div className="unique-custom-service-form message bot-message">
              <form onSubmit={submitForm}>
                <input
                  style={{ outline: 'none', width: '100%' }}
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name"
                  required
                />
                <input
                  style={{ outline: 'none', width: '100%' }}
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  required
                />
                <textarea
                  style={{ outline: 'none', width: '100%' }}
                  id="message"
                  name="message"
                  placeholder="Your Message"
                  required
                />
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
        </div>

        {/* <div className="unique-user-input-container">
          <input
            type="text"
            id="user-input"
            placeholder="Type your message..."
            onKeyPress={handleKeyPress}
          />
        </div> */}
      </div>
    )}
  </div>
  );
};

export default Chatbox;
