import React, { useState, useEffect } from 'react';
import Header from "../Components/Header";
import Footer from "../Components/footer";
import Faq from '../Components/Faq';
import Testimonial1 from '../Components/Testimonial1';
import Form from '../Components/Form';
import Chatbox from '../Components/Chatbox';
import Blog1 from '../Components/Blog1';
import SEOPage from '../Components/SEOPage';
import { Helmet } from "react-helmet";
import Video from '../Components/Video';


const Homepage1 = () => {

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    fetch('https://blog.datametricks.com/wp-json/wp/v2/categories')
      .then((response) => response.json())
      .then((data) => setCategories(data.slice(0, 3)))
      .catch((error) => console.error('Error fetching categories:', error));
  }, []);




  const schemaData1 = 
  [
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "name": "DataMetricks Consulting Pvt Ltd",
      "url": "https://www.datametricks.com/",
      "logo": "https://www.datametricks.com/logo.png",
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "+91 7303946657",
          "contactType": "Customer Service",
          "email": "contact@datametricks.com",
          "areaServed": "IN",
          "availableLanguage": "English"
        }
      ],
      "address": [
        {
          "@type": "PostalAddress",
          "streetAddress": "A40, ITHUM TOWER, Industrial Area, Sector 62",
          "addressLocality": "Noida",
          "addressRegion": "Uttar Pradesh",
          "postalCode": "201309",
          "addressCountry": "IN"
        }
      ],
      "sameAs": [
        "https://www.facebook.com/people/DataMetricks-Consulting-PvtLtd/61554350043470/",
        "https://www.instagram.com/datametricks_pvt_ltd/",
        "https://x.com/datametricks",
        "https://www.linkedin.com/company/datametricks-consulting-pvt-ltd/"
      ]
    }
  ];
  
  
   

  const faqSchemaData1 = 
  [
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What services does Datametricks offer?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Datametricks offers a range of services including website design and development, mobile app development, SEO, social media marketing, performance marketing, and CMS development."
        }
      },
      {
        "@type": "Question",
        "name": "How can I get a quote for a website development project?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "You can easily request a quote by visiting our 'Contact Us' page or reaching out to us via email at contact@datametricks.com or phone at +91 7303946657."
        }
      },
      {
        "@type": "Question",
        "name": "Does Datametricks provide mobile app development services?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, Datametricks offers mobile app development services for both Android and iOS platforms, ensuring high-quality apps tailored to your business needs."
        }
      },
      {
        "@type": "Question",
        "name": "Can Datametricks help with SEO for my website?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, we provide comprehensive SEO services to help improve your website's ranking on search engines and drive organic traffic."
        }
      },
      {
        "@type": "Question",
        "name": "How can Datametricks help with digital marketing?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Datametricks offers a full suite of digital marketing services, including social media marketing, performance marketing, SEO, and content marketing, designed to boost your brand's online presence and engagement."
        }
      }
    ]
  }
];

const verifiedagencybatch = "https://www.datametricks.com/website-image/VerfifedAgencyBatch.svg";
const clutchimg = "https://www.datametricks.com/website-image/clutchimg.svg";
const clutching2 = "https://www.topdevelopers.co/assets/badges/app-2024/Web-Development/badge-top-web-development-companies-2024.png";
// const googleclutchimg = "https://www.datametricks.com/website-image/googlebatchimg.svg"; 
const component12 = "https://www.datametricks.com/website-image/Component 12.svg";
const component13 = "https://www.datametricks.com/website-image/Component 13.svg";
const newhomeicon1 = "https://www.datametricks.com/website-image/newhomeicon-1.svg";
const newhomeicon2 = "https://www.datametricks.com/website-image/newhomeicon-2.svg";
const newhomeicon3 = "https://www.datametricks.com/website-image/newhome-icon-3.svg";
const newhomeicon4 = "https://www.datametricks.com/website-image/newhomeicon-4.svg";
const newhomeicon5 = "https://www.datametricks.com/website-image/newhomeicon-5.svg";
const newhomeicon6 = "https://www.datametricks.com/website-image/newhomeicon-6.svg";
const newhomeimg1 = "https://www.datametricks.com/website-image/newhome-img1.png";
const newhomeimg2 = "https://www.datametricks.com/website-image/newhome-img2.svg";
const newhomeimg3 = "https://www.datametricks.com/website-image/newhome-img3.svg";
const newhomeimg4 = "https://www.datametricks.com/website-image/newhome-img4.svg";
const newhomeimg5 = "https://www.datametricks.com/website-image/newhome-img5.svg";
const newhomeimg6 = "https://www.datametricks.com/website-image/hseoimg1.svg";
const newhomeimg7 = "https://www.datametricks.com/website-image/hseoimg2.svg";
const newhomeimg8 = "https://www.datametricks.com/website-image/hseoimg3.svg";
const newhomeimg9 = "https://www.datametricks.com/website-image/hseoimg4.svg";
const newhomeimg10 = "https://www.datametricks.com/website-image/hseoimg5.svg";
const newhomeimg11 = "https://www.datametricks.com/website-image/newhome-img11.svg";
const newhomeimg12 = "https://www.datametricks.com/website-image/newhome-img12.svg";
const newhomeimg13 = "https://www.datametricks.com/website-image/proimg1.svg";
const newhomeimg14 = "https://www.datametricks.com/website-image/newhome-img14.svg";
const newhomeimg15 = "https://www.datametricks.com/website-image/proimg2.svg";
const newhomeimg16 = "https://www.datametricks.com/website-image/hpageimg1.svg";
const newhomeimg17 = "https://www.datametricks.com/website-image/hpageimg2.svg";
const newhomeimg18 = "https://www.datametricks.com/website-image/hpageimg3.svg";
const newhomeimg19 = "https://www.datametricks.com/website-image/hpageimg4.svg";
const newhomeimg20 = "https://www.datametricks.com/website-image/hpageimg5.svg";
const newhomeimg21 = "https://www.datametricks.com/website-image/hpageimg6.svg";
const newhomeimg22 = "https://www.datametricks.com/website-image/homepage1com.svg";


  var faqData=[

    {que:"1. How can Datametricks help my business grow?",ans:"We help businesses grow by enhancing their online presence through effective digital marketing strategies and high-quality website development. Our services are tailored to meet your specific business needs, driving more traffic, engagement, and conversions." },
    {que:"2. What industries do you specialize in? ", ans:"We work with clients from various industries, including information technology, healthcare, e-commerce, real estate, education, and professional services. Our diverse experience allows us to create customized solutions for each industry."},
    {que:"3. How does your SEO service improve my website’s ranking? ",ans:"Our SEO service includes comprehensive strategies such as keyword research, on-page optimization, technical SEO, and off-page SEO. By implementing these techniques, we improve your website's visibility and ranking on search engines, driving more organic traffic."},
    {que:"4. What is the process for getting started with Datametricks? ",ans:"Getting started with Datametricks is easy. Simply contact us through our website or fill out the form on our home page. We’ll schedule a consultation to discuss your needs and develop a customized plan for your business. "},
    {que:"5. Can you help with social media marketing? ",ans:"Yes, we offer comprehensive social media marketing services that include content creation, campaign management, and performance analysis to help you engage with your audience and grow your brand on social media platforms. "},
    {que:"6. Do you offer support and maintenance for websites? ",ans:"Absolutely. We provide ongoing support and maintenance services to ensure your website remains up-to-date, secure, and performs optimally. This includes regular updates, security checks, and performance enhancements. "},
    {que:"7. What makes Datametricks different from other digital marketing agencies? ",ans:"Datametricks stands out due to our personalized approach, industry expertise, and commitment to delivering measurable results. We tailor our strategies to meet your specific goals and use the latest technologies to ensure your success. "},
    {que:"8. How do you ensure the security of my website and data? ",ans:"We prioritize security by implementing best practices in website development and data protection. Our measures include secure coding practices, regular security audits, data encryption, and compliance with relevant regulations. "},
    {que:"9. Can you provide examples of your previous work? ",ans:"Yes, we have a portfolio of successful projects across various industries. Visit our portfolio section on the website to see case studies and examples of how we’ve helped other businesses achieve their goals. "},

  ];


  // const testimonials = [
  //   {
  //     text: 'my name is Sanjeet. I am running my own company in the name of Narayan Home Facility Service Private Limited in Sector 57, Gurgaon. For which I am getting my mobile application website design and Facebook ad promotion done by DataMetricks consulting Pvt Ltd Company, which is currently working and I have been associated with them for the last 4 years. They have supported me every time and have done the best work. And I hope that you people also join them, give your work to them, you will get the best result and you will get growth for your business, so thank you Mr. Sandeep ji. And I have lots of best wishes and hope from my side to the entire team of DataMetricks consulting Pvt Ltd May you all move ahead in life and keep doing good to others. Do good and good will happen! ',
  //     author: 'Sanjeet',
  //     title: 'Founder of Narayan Home Facility Service Private Limited ',
  //   },
  //   {
  //     text: 'I have great experience with DataMetricks Consulting Private Limited, I am constantly impressed this Company. Thank you for your hard work!',
  //     author: 'Vrindavan Kunj Gali, Vrindavan, India',
  //     title: 'CEO of *******',
  //   },
  //   {
  //     text: 'One of Noida finest digital marketing companies, boasting a team of experts who excel in elevating your online presence. Highly recommended!',
  //     author: 'HealthoMax Drqlick',
  //     title: '',
  //   },
  //   {
  //     text: 'This digital marketing company in Noida sets the standard with its skilled team and cutting-edge strategies, truly exceptional.',
  //     author: 'Rachana singh',
  //     title: '',
  //   },
  // ];

  // const [currentTestimonial, setCurrentTestimonial] = useState(0);

  // const handlePrevious = () => {
  //   setCurrentTestimonial(
  //     currentTestimonial === 0 ? testimonials.length - 1 : currentTestimonial - 1
  //   );
  // };

  // const handleNext = () => {
  //   setCurrentTestimonial(
  //     currentTestimonial === testimonials.length - 1 ? 0 : currentTestimonial + 1
  //   );
  // };

    const texts = [
        "SEO (Search Engine Optimization)",
        "Web Development",
        "Mobile App Development",
        "Digital Marketing",
        "Graphic Designing",
        "Performance Marketing",
        "Artificial Intelligence"
      
       
      ];

      const [currentIndex, setCurrentIndex] = useState(0);

      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 2000); 
    
        return () => clearInterval(interval);
      }, [texts.length]);

    return (
        
      <div>
        <SEOPage 
                title="Website Design & Development Agency in Noida | Mobile App Development, SEO, DigitalMarketing"
                description="Datametricks is a leading website design and development company in Noida, offering
                              comprehensive digital solutions including mobile app development, SEO services, social media
                              marketing, performance marketing, and CMS development. Grow your business with our expertdriven web and digital marketing services."
                canonicalUrl="https://www.datametricks.com/"
            />
  
        <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaData1)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(faqSchemaData1)}
        </script>
        
      </Helmet>
     
   
         

                 {/* section 1 */}
          <section>
               <div className="">
                      <Header />
               </div>
            
           </section>

           <section className='newhome-firstcon' style={{marginTop:'5%'}}>
            <Video />
           </section>

           {/* <section
  className="py-5 newhome-firstcon"
  style={{ backgroundColor: 'rgba(251, 251, 251, 1)' }}
>
  <div className="container pt-10" style={{paddingTop:'85px'}}>
    <div className="row">
      <div className="col-lg-7 col-md-12">
    

        <div
          className="d-flex gap-5 newhome-firstcon2"
       
        >
          <div className="d-flex gap-lg-3   ">
            <div >
            <img src={component12} alt="" className='d-none d-lg-block d-sm-none d-md-block pt-1'/>
              <img src={newhomeicon1} alt="" className='d-block d-lg-none d-sm-block d-md-none'/>
            </div>
            <div>
            <p className="fw-700 plus newfont-20 fs-20  font-17">
                Digital Marketing
              </p>
            </div>
          </div>

          <div className="d-flex gap-lg-3 ">
            <div >
              <img src={component13} alt="" className='d-none d-lg-block d-sm-none d-md-block pt-1'/>
              <img src={newhomeicon2} alt="" className='d-block d-lg-none d-sm-block d-md-none'/>
            </div>
            <div>
              <p className="fw-700 plus fs-20 newfont-20  font-17">
                Web Development
              </p>
            </div>
          </div>
        </div>
        <p className="fw-700 fs-50 newfont-50 plus mb-0 font-25">
          Empowering
          <span
            className="fw-700 fs-50 plus newfont-50 font-25"
            style={{ color: 'rgba(0, 83, 154, 1)' }}
          >
            {' '}
            Brands
          </span>{' '}
          with
        </p>

        <div className="scrolling-container">
          <div
            className="scrolling-text-wrapper"
            style={{ transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {texts.map((text, index) => (
              <div
                key={index}
                className={`newfont-25 scrolling-text ${
                  index === currentIndex ? 'visible' : ''
                }`}
              >
                {text}
              </div>
            ))}
          </div>
        </div>

        <p
          className="fs-17 newfont-17 plus fw-400 pt-3 pt0 font-17"
          style={{ color: 'rgba(34, 34, 34, 0.6)' }}
        >
          Discover innovative IT solutions designed to transform your business.
          Our expertise in web development, digital marketing, and mobile app
          creation ensures your digital journey leads to tangible growth and
          success.
        </p>
        <a href='/contact-us'>
        <button
          type="button"
          className="btn btn-sm px-5 mt-3 pb-0 btn1"
          style={{
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            backgroundColor: 'rgba(0, 110, 205, 1)',
            borderRadius: '42px',
          }}
        >
          <p className="fs-19  fw-600 lexend text-white pt-2">
            Contact us
          </p>
        </button></a>
           
   
          <div className='d-flex gap-5 pt-4 textcenter'>
               <div className='' >
                <a href='https://www.designrush.com/'><img src={verifiedagencybatch} alt="We're on DesignRush" className='hw85' style={{height:'120px',width:'120px'}} /></a>
               </div>
               <div  >
                <a href='https://clutch.co/profile/datametricks-consulting-private' ><img src={clutchimg} alt="" className='hw85' style={{height:'100px',width:'100px'}} /></a>
               </div>
               <div  >
                <a href='https://www.topdevelopers.co/profile/datametricks' ><img src={clutching2} alt="" className='hw85' style={{height:'100px',width:'100px'}} /></a>
               </div>
             
        </div>

     
      </div>
      <div className="col-lg-5 col-md-12" style={{position:'relative'}}>
        <img src={newhomeimg1} alt="" className="img-fluid newhomeimgclass " />
    <a href='/our-completed-projects' style={{cursor:'pointer'}}>    <img src={newhomeimg22} alt="" className="img-fluid hidepara" style={{position:'absolute',left:'-47%',right:'0',top:'65%',bottom:'0'}} /></a>

      </div>
    </div>
  </div>
</section> */}

        {/* section 2 */}

            {/* <section className="py-5 newhome-firstcon" style={{backgroundColor:'rgba(251, 251, 251, 1)',marginTop:'65px'}}>
                <div className="container ">
                    <div className="row ">
                    <div className="col-lg-7 col-md-12">
                        <div className="d-flex gap-5 newhome-firstcon2" style={{marginTop:'50px'}}>
                            <div className="d-flex gap-3">
                                 <div>
                                        <img src={newhomeicon1} alt="" />
                                </div>
                                <div>
                                        <p className="fw-700 plus newfont-20 fs-20 pt-1">Digital Marketing </p>
                                </div>
                            </div>
                          
                            <div className="d-flex gap-3">
                                 <div>
                                        <img src={newhomeicon2} alt="" />
                                </div>
                                <div>
                                        <p className="fw-700 plus fs-20 newfont-20 pt-1 ">Web Development </p>
                                </div>
                            </div>
                           
                        </div>
                        <p className="fw-700 fs-50 newfont-50 plus mb-0">Empowering <span className="fw-700 fs-50 plus newfont-50" style={{color:'rgba(0, 83, 154, 1)'}}> Brands</span> with </p>

                        <div className="scrolling-container">
                          <div className="scrolling-text-wrapper" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                                 {texts.map((text, index) => (
                          <div
                           key={index}
                              className={`newfont-25 scrolling-text ${index === currentIndex ? 'visible' : ''}`}
                             >
                         {text}
                        </div>
                     ))}
                       </div>
                 </div>

                        <p className="fs-17 newfont-17 plus fw-400 pt-3 pt0" style={{color:'rgba(34, 34, 34, 0.6)'}}>
                        Discover innovative IT solutions designed to transform your business. Our
 expertise in web development, digital marketing, and mobile app creation
 ensures your digital journey leads to tangible growth and success.</p>
                        <button type="button" className="btn btn-sm px-5 mt-3 pb-0" style={{border:'none',outline:'none', boxShadow:'none',backgroundColor:'rgba(0, 110, 205, 1)',borderRadius:'42px'}}><p className="fs-18 newfont-17 fw-600 plus text-white pt-1">Contact us</p></button>
                    </div>
                    <div className="col-lg-5 col-md-12">
                            <img src={newhomeimg1} alt="" className="img-fluid newhomeimgclass" />
                        </div>
                </div>
                </div>
            </section> */}

          {/* Section-3  */}

         {/* ======================================= */}

        {/* ======================================= */}
       
          <section className='mt-lg-5  '>
  <div className='container' style={{ border: '1px solid rgba(231, 231, 231, 1)', borderRadius: '15px' }}>
    <div className='row p-2 p0'>
      <div className='col-lg-5 col-md-6 col-sm-12 hidepara' style={{paddingLeft:'0'}}>
        <img src={newhomeimg2} alt='' className='img-fluid' />
      </div>
      <div className='col-lg-7 col-md-6 col-sm-12'>
        <p className='fw-600 fs-32 font-22 plus textcenter font-23 '>Working Together Towards Digital Excellence</p>
        <p className='fs-18 font-18 plus textcenter  font-17' style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
        We believe in the power of collaboration to unlock the full potential of your  business. Our comprehensive IT solutions, from bespoke web development to cutting-edge digital  marketing, are crafted to elevate your brand's online presence. With a dedicated team, we ensure  every strategy is optimized for maximum impact and tailored to your unique needs.
        </p>

        <div className='row mt-5'>
          <div className='col-md-6 col-sm-12 mb-3 colsm13 d-flex align-items-center'>
            <div
              className='d-flex gap-lg-4 gap12  p-3 padding-5 h-100 width150 justify-content-center'
              style={{ backgroundColor: 'rgba(248, 248, 248, 1)', borderRadius: '6px',width:'300' }}
            >
              <div>
                <img src={newhomeicon3} alt='Customized Solutions Icon'  />
              </div>
              <div>
                <p className='fw-600 fs-19 font-15 plus pt-2 pt0' style={{ color: 'rgba(53, 53, 53, 1)' }}>
                  Customized Solutions
                </p>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-sm-12 mb-3 colsm13 d-flex align-items-center'>
            <div
              className='d-flex gap-lg-4 gap12 p-3 padding-5 h-100 width150 justify-content-center'
                style={{ backgroundColor: 'rgba(248, 248, 248, 1)', borderRadius: '6px',width:'300px' }} >
              <div>
                <img src={newhomeicon4} alt='Industry Pioneers Icon' />
              </div>
              <div>
                <p className='fw-600 fs-19 font-15 plus pt-2 pt0' style={{ color: 'rgba(53, 53, 53, 1)' }}>
                  Industry Pioneers
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='row '>
          <div className='col-md-6 col-sm-12 mb-3 colsm13 d-flex align-items-center'>
            <div
              className='d-flex gap-lg-4  gap12 p-3 padding-5 h-100  width150 justify-content-center'
              style={{ backgroundColor: 'rgba(248, 248, 248, 1)', borderRadius: '6px',width:'300px' }}
            >
              <div>
                <img src={newhomeicon5} alt='Strategic Innovation Icon'  />
              </div>
              <div>
                <p className='fw-600 fs-19 font-15 plus pt-2 pt0 ' style={{ color: 'rgba(53, 53, 53, 1)' }}>
                  Strategic Innovation
                </p>
              </div>
            </div>
          </div>

          <div className='col-md-6 col-sm-12 mb-3 colsm13 d-flex align-items-center'>
            <div
              className='d-flex gap-lg-4 gap12 p-3 padding-5 h-100 width150  justify-content-center'
              style={{ backgroundColor: 'rgba(248, 248, 248, 1)', borderRadius: '6px',width:'300px' }}
            >
              <div>
                <img src={newhomeicon6} alt='Increase Credibility Icon' />
              </div>
              <div>
                <p className='fw-600 fs-19 font-15 plus pt-2 pt0' style={{ color: 'rgba(53, 53, 53, 1)' }}>
                  Increase Credibility
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


   <section>
           <div className='mt-lg-5 newhome-firstcon' style={{backgroundColor:'rgba(251, 251, 251, 1)'}}>
                     <div className='container pt-4 pb-5 pb0' >
                 
                        <div className='row'>
                          <div className='col-lg-5' >
                            <img src={newhomeimg3} alt='' className='img-fluid' />
                          </div>
                          <div className='col-lg-7 textcenter  pl-0' style={{paddingLeft:'50px'}}>
                          <div className="d-flex gap-4 pt-4 textcenter ">
                                 <div>
                                        <img src={component12} alt="" />
                                </div>
                                <div className=''>
                                        <p className="fw-700 plus newfont-20 fs-20 font-17 ">Digital Marketing </p>
                                </div>
                            </div>
                            <p className='fw-600 fs-30 font-32 plus mt-2 pl-pr font-23'>Get Found, Generate Leads, Achieve Results</p>
        <p className='fs-17 font-18 plus pl-pr font-17' style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
        We offer tailored strategies encompassing SEO, social media marketing,  content marketing, and PPC campaigns designed to boost your online visibility, attract  qualified leads, and deliver measurable results. Our expert team is dedicated to helping  your brand stand out in a crowded digital landscape, ensuring sustained growth and  success. Partner with us to elevate your digital presence and achieve your business goals.
        </p>
                          </div>
                        </div>

                      
                        <div className='row mt-4 mt0'>
                          <div className='col-lg-5 col-md-6 col-sm-12 hidepara' >
                     
                              <div className='row '>
                                <div className='col-md-6 col-sm-6 col-6'>
                                <img src={newhomeimg4} alt="" className='img-fluid'/>
                                </div>
                                <div className='col-md-6 col-sm-6 col-6 '>
                                <img src={newhomeimg5} alt="" className='img-fluid'/>
                                  </div>
                              </div>
                          </div>
                         

                          <div className='col-lg-7 col-md-12 col-sm-12 pl-0' style={{paddingLeft:'50px'}}>
 
  <p className='fw-600 fs-28 plus newfont-18 newhomeimgclass pt0'>Complete Digital Marketing Solutions</p>
  
 
 <a href='/service/search-engine-optimization-seo' style={{textDecoration:'none'}}> <div className='mt-10' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
    <div className='row p-1'>
      
    
      <div className='col-md-4 col-sm-4 col-4 colsm4 colsm5'>
        <img src={newhomeimg6} alt="" className='img-fluid w-100'/>
      </div>
      
    
      <div className='col-md-8 col-sm-8 col-8 colsm8 d-flex align-items-center'>
        <div>
      
        <p className='fw-600 fs-16 plus w-100 pt-20 textcenter mb-0  text-black '>SEO (Search Engine Optimization)</p>

        <p className='fs-15 plus w-100 textcenter pt-1 ' style={{color:'rgba(0, 0, 0, 0.45)'}}>
          Boost your website's visibility and organic traffic with our expert SEO services. We optimize your site to rank higher on search engines, driving more potential customers to your business.
        </p>
        </div>
      </div>
    </div>
  </div></a>
</div>

                        
                        </div>

     <div className='row mt-5 mt-12 mt-10'>
 
 <div className='col-lg-6 col-md-6 col-sm-12 col-12 colsm12'>
 <a href='/services/performance-marketing' style={{textDecoration:'none'}}><div className=' bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
      <div className='row p-1'>
        <div className='col-md-4 col-sm-12 col-12'>
          <img src={newhomeimg7} alt="" className='img-fluid w-100'/>
        </div>
        <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
          <div>
          <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black'>Performance Marketing</p>
          <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
            Maximize your ROI with our data-driven performance marketing solutions. We focus on measurable outcomes, using analytics to refine campaigns and deliver the best possible results for your business.
          </p>
          </div>
        </div>
      </div>
    </div></a>
  </div>


  <div className='col-lg-6 col-md-6 col-sm-12 col-12 colsm12'>
    <div className='bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
    <a href='/service/social-media-marketing' style={{textDecoration:'none'}}>  <div className='row p-1'>
        <div className='col-md-4 col-sm-12 col-12'>
          <img src={newhomeimg8} alt="" className='img-fluid w-100'/>
        </div>
        <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
          <div>
          <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black'>Social Media Marketing</p>
          <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
            Engage with your audience and grow your brand with our dynamic social media marketing strategies. We create compelling content and targeted campaigns to increase your reach and drive conversions.
          </p>
          </div>
        </div>
      </div></a>
    </div>
  </div>


  <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-4 mt0 colsm12'>
    <div className='bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
      <div className='row p-1'>
        <div className='col-md-4 col-sm-12 col-12'>
          <img src={newhomeimg9} alt="" className='img-fluid w-100'/>
        </div>
        <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
          <div>
          <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black' style={{color:'rgba(var(--bs-link-color-rgb)'}}>Email Marketing</p>
          <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
            Reach your customers directly with our targeted email marketing campaigns. We design and execute personalized email strategies that enhance engagement and drive sales.
          </p>
          </div>
        </div>
      </div>
    </div>
  </div>

 
  <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-4 mt0 colsm12'>
    <div className='bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
    <a href='/services/graphic-desinging' style={{textDecoration:'none'}}>  <div className='row p-1'>
        <div className='col-md-4 col-sm-12 col-12'>
          <img src={newhomeimg10} alt="" className='img-fluid w-100'/>
        </div>
        <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
          <div>
          <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black'>Graphic Designing</p>
          <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
            Make a lasting impression with our creative graphic design services. From logos to marketing materials, we craft visually appealing designs that effectively communicate your brand’s message.
          </p>
          </div>
        </div>
      </div></a>
    </div>
  </div>
</div>        
                     </div>
            </div>          
    </section>

    <section>
       <div className='container textcenter text-center  mt-3'>
            <p className='fw-600 fs-35 plus mb-0 font-23'>Our Success Stories</p>
            <p className='fw-600 fs-35 plus font-23 '>Transformative Projects and <span style={{color:'rgba(0, 83, 154, 1)'}}>Case Studies </span></p>

           
       </div>
    </section>

    
 <section>
      <div className='p-5  p-20 mt-4' style={{backgroundColor:'rgba(0, 83, 154, 1)'}}>
       <div className='container  '>
           <div className='row justify-content-center'>
            <div className='col-lg-4'>
          <a href='/our-completed-projects/lookplex'>    <div className='hovercontainer  '>
                <img src={newhomeimg12} alt='' className='img-fluid image123  ' />
                <div class="overlay123">
                     <div class="text123">Salon at Home Services Website</div>
                     <div class="text1234">For a salon at home services provider, we designed a sleek and modern website with an easy
                          booking feature. The site allows customers to book appointments effortlessly and explore various
                            beauty services offered.</div>
                 </div>
               </div></a>
            </div>
            <div className='col-lg-4'>
            <a href='/our-completed-projects/smartcare'> <div className='hovercontainer  '>
                <img src={newhomeimg13} alt='' className='img-fluid pt-20 image123  ' />
                <div class="overlay123">
                     <div class="text123">Smartcare</div>
                     <div class="text1234">We created a healthcare platform, SmartCare, that enables patients to easily book appointments, find doctors, store medical records, and save vital health information, streamlining their healthcare experience.</div>
                 </div>
               </div></a>

            </div>
           </div>
           <div className='row justify-content-center mt-4'>
            <div className='col-lg-4'>
          <a href='/our-completed-projects/monovouge'>  <div className='hovercontainer  '>
                <img src={newhomeimg14} alt='' className='img-fluid mt0 image123  ' />
                <div class="overlay123">
                     <div class="text123 "> E-commerce Platform</div>
                     <div class="text1234">We developed a dynamic and visually appealing e-commerce platform that enhances the
shopping experience. The site includes secure payment gateways, intuitive navigation, and a
responsive design for seamless browsing on all devices.</div>
                 </div>
               </div></a>

            </div>
            <div className='col-lg-4 hidepara'>
            <a href='/our-completed-projects/healthomax-website'> <div className='hovercontainer'>
                <img src={newhomeimg15} alt='' className='img-fluid mt0 image123  ' />
                <div class="overlay123">
                     <div class="text123"> Healthomax</div>
                     <div class="text1234">We developed the HealthOmax mobile application for both Android and iOS platforms. The app offers the same features as the website, allowing medical professionals to connect and collaborate, and patients to interact with doctors on the go.</div>
                 </div>
               </div></a>

            </div>
           </div> 
       </div>
       </div>
    </section>  

   <section >
    <div className='mt-lg-4'>
      <Testimonial1/>
    </div>
   </section>
{/* 
    <section >
    <div className="testimonial-slider">
      <div className="testimonial-content">
        <p className="testimonial-text">{testimonials[currentTestimonial].text}</p>
        <div className="author-info">
          <div className="author-image  hidepara"></div>
          <div className="author-details">
            <h4 className="author-name">{testimonials[currentTestimonial].author}</h4>
            <p className="author-title">{testimonials[currentTestimonial].title}</p>
          </div>
        </div>
      </div>
      <div className="navigation-arrows">
        <div className="arrow" onClick={handlePrevious}>
            <img src={leftarrow} alt='' />
        </div>
        <div className="arrow" onClick={handleNext}>
             <img src={rightarrow} alt='' />

        </div>
      </div>
      <div className="pagination-dots">
        {testimonials.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentTestimonial ? 'active' : ''}`}
            onClick={() => setCurrentTestimonial(index)}
          ></span>
        ))}
      </div>
    </div>
    </section> */}

    

    <section>
        <div className='container mt-30' style={{marginTop:'50px'}}>
        <div className='row'>
                          <div className='col-lg-5'>
                            <img src={newhomeimg11} alt='' className='img-fluid' />
                          </div>
                          <div className='col-lg-7 textcenter pl-0 ' style={{paddingLeft:'50px'}}>
                          <div className="d-flex gap-4 pt-4 textcenter ">
                                 <div>
                                        <img src={component13} alt="" />
                                </div>
                                <div className=''>
                                        <p className="fw-700 plus newfont-20 fs-20  font-17 ">Web Development </p>
                                </div>
                            </div>
                            <p className='fw-600 fs-30 font-32 plus mt-2 pl-pr font-23'>Let's Build Something Amazing Together</p>
        <p className='fs-17 font-18 plus pl-pr font-17' style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
        We specialize in creating dynamic, responsive, and user-friendly websites that bring your vision  to life. Our expert team of developers works tirelessly to ensure that your site not only looks  stunning but also functions seamlessly across all devices. Whether you need a simple  informational site or a complex e-commerce platform, we have the skills and experience to  deliver top-notch solutions tailored to your specific needs.
        </p>
                          </div>
                        </div>
        </div>
    </section>

    <section>
       <div className='container textcenter  mt-4 mt0'>
            <p className='fw-600 fs-30 plus font-23'>All Inclusive Web Development Services  </p>    
       </div>
    </section>

    <section>
       <div className='container textcenter  mt-4'>
       <div className='row  mt-12 mt-10'>
 
 <div className='col-lg-6 col-md-6 col-sm-12 col-12 colsm12'>
<a href='/service/custom-website-design-development-solutions' style={{textDecoration:'none'}}><div className=' bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
     <div className='row p-1'>
       <div className='col-md-4 col-sm-12 col-12'>
         <img src={newhomeimg16} alt="" className='img-fluid w-100'/>
       </div>
       <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
        <div>
         <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black'>Custom Website Design</p>
       
        <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
         Create a unique and visually appealing website tailored to your brand. Our custom designs  ensure your site stands out and engages your audience effectively.
         </p>
         </div>
       </div>
     </div>
   </div></a>
 </div>


 <div className='col-lg-6 col-md-6 col-sm-12 col-12 colsm12'>
 <a href='/service/ecommerce-website-design-development-solutions' style={{textDecoration:'none'}}>  <div className='bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
     <div className='row p-1'>
       <div className='col-md-4 col-sm-12 col-12'>
         <img src={newhomeimg17} alt="" className='img-fluid w-100'/>
       </div>
       <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
        <div>
         <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black ' >E-commerce Solutions</p>
         <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
         Boost your online sales with our robust e-commerce solutions. We develop secure, scalable, and  user-friendly online stores that enhance the shopping experience for your customers.
         </p>
         </div>
       </div>
     </div>
   </div></a>
 </div>


 <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-4 mt0 colsm12'>
 <a href='/service/cms-website-design-development-solutions' style={{textDecoration:'none'}}>  <div className='bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
     <div className='row p-1'>
       <div className='col-md-4 col-sm-12 col-12'>
         <img src={newhomeimg18} alt="" className='img-fluid w-100'/>
       </div>
       <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
        <div>
         <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black' >Content Management Systems (CMS)</p>
         <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
         Manage your website content effortlessly with our CMS solutions. We provide easy-to-use  platforms that allow you to update and maintain your site with minimal technical knowledge.
         </p>
         </div>
       </div>
     </div>
   </div></a>
 </div>


 <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-4 mt0 colsm12'>
 <a href='/service/mobile-app-design-development-solutions' style={{textDecoration:'none'}}>  <div className='bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
     <div className='row p-1'>
       <div className='col-md-4 col-sm-12 col-12'>
         <img src={newhomeimg19} alt="" className='img-fluid w-100'/>
       </div>
       <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
        <div>
         <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black' >Mobile-Responsive Design</p>
         <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
         Ensure your website looks great on all devices with our mobile-responsive design services. We  create sites that provide a seamless user experience across smartphones, tablets, and desktops.
         </p>
         </div>
       </div>
     </div>
   </div></a>
 </div>

 <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-4 mt0 colsm12'>
 <a href='/service/search-engine-optimization-seo' style={{textDecoration:'none'}}>  <div className='bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
     <div className='row p-1'>
       <div className='col-md-4 col-sm-12 col-12'>
         <img src={newhomeimg20} alt="" className='img-fluid w-100'/>
       </div>
       <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
        <div>
         <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black' >SEO Optimization</p>
         <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
         Increase your website's visibility on search engines with our SEO optimization services. We  implement strategies that drive organic traffic and improve your search engine rankings.
         </p>
         </div>
       </div>
     </div>
   </div></a>
 </div>

 <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-4 mt0 colsm12'>
 <a href='/service/website-design-development-solutions' style={{textDecoration:'none'}}>  <div className='bordernone' style={{border:'1px solid rgba(231, 231, 231, 1)', borderRadius:'5px'}}>
     <div className='row p-1'>
       <div className='col-md-4 col-sm-12 col-12'>
         <img src={newhomeimg21} alt="" className='img-fluid w-100'/>
       </div>
       <div className='col-md-8 col-sm-12 col-12 d-flex align-items-center'>
        <div>
         <p className='fw-600 fs-16 plus pt-10 textcenter mb-0  text-black' >Website Maintenance and Support</p>
         <p className='fs-15 plus hidepara pt-1' style={{color:'rgba(0, 0, 0, 0.45)'}}>
         Keep your website running smoothly with our maintenance and support services. We offer  regular updates, security checks, and technical assistance to ensure optimal performance.
         </p>
         </div>
       </div>
     </div>
   </div></a>
 </div>
</div>  
       </div>
    </section>


<section className=''>
        <div className='newhome-bg1 resnewhome-bg1'>  
  <div className='container p-30'>
    <div className='row justify-content-center'>
      <div className='col-lg-7 col-md-6 col-12 textcenter '>
          <h2 className='fs-50 fw-700 plus text-white font-25 mt-35' style={{marginTop:'200px'}}>Transform Your Digital Presence</h2>
          <p className='fs-18 fw-400 plus font-17' style={{color:'rgba(192, 199, 206, 1)'}}>Ready to transform your digital presence? <br/> Fill out the form below to get a free consultation or a  quote for our top-notch website development and digital marketing services. Let's take your  business to new heights together!</p>
      </div>
      <div className='col-lg-4 col-md-6 col-12 '>
         <div>
          <Form/>
         </div>
        </div>
    </div>
  </div>
  </div>
  
</section>


<section className='mt-lg-4 '>
  <div>
      <p className='text-center fw-600 fs-35 plus font-23'>Latest From The <span style={{ color: 'rgba(0, 83, 154, 1)' }}>Blog</span></p>
  </div>

      {categories.map((category) => (
        <div key={category.id}>
              <Blog1 categoryId={category.id} />
          
        </div>  
      ))} 
 
</section>


<section>
  <div className='container mt-10'>
  <div className='row justify-content-center'>
    <div className='col-lg-8'>
      <div className='mt-lg-4'>
    <Faq faqData={faqData} />
    </div>
  </div>
 

    </div>
     
  </div>
</section>

<section>
  <div>
    <Chatbox/>
  </div>
</section>

             <section >
              <div className="mt-5" >
                     <Footer />
                     </div>
           </section>

     </div>
  );
};
export default Homepage1;